<template>
  <div class="legal-care">
    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <img
          :src="`${this.$urlBucketImage}/legal-care.jpg`"
          class="img-fluid"
          style="border-radius: 25px; width: 450px"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <h1 class="title mb-0">SlothMove Legal Care</h1>

<!--      <b-badge
        pill
        v-if="userData.plan === 2"
        class="ml-2 badge-glow badge-primary ml-2 font-weight-bolder"
        :to="{ name: 'pages-pricing' }"
      >
        PLUS
      </b-badge>-->

      <!-- <b-badge
        pill
        v-if="userPlan.plan < 1"
        class="ml-2 badge-success "
        :to="{ name: 'pages-pricing' }"
      >
        upgrade
      </b-badge> -->
    </div>

    <div class="cards row mt-2">
      <div class="col-12 col-md-11">
        <p v-if=" userPlan.plan > 0 ">
          SlothMove Legal Care is included in the SlothMover and Jungle Sovereign subscriptions and provides support across dozens of areas for whenever you need it. Should you ever need to use it, simply write an email with your preferred time to hello@slothmove.com and a legal expert will reach out within 24 hours
        </p>
        <p v-if=" userPlan.plan < 1 || userPlan.plan == undefined ">
          SlothMove Legal Care is included in the SlothMover and Jungle Sovereign subscriptions and provides support across dozens of areas for whenever you need it. Should you ever need to use it, simply upgrade, let us know, and a legal expert will reach out within 24 hours
        </p>
      </div>
    </div>

    <div class="cards row mt-2">
      <div class="col-12 col-md-11">
        <div class="row">
          <div v-for="(item, index) in careCardsInfo1" :key="index" class="col-12 col-sm-6 col-xl-3" style="padding-bottom:2rem">
            <b-card class="py-1">
              <div class="d-flex justify-content-center ">
                <div>
                  <div class="d-flex justify-content-center w-100">
                    <img :src="item.img" width="50px" />
                  </div>
                  <div class="text-center">
                    <h3 class="card-title my-1">{{ item.title }}</h3>
                    <p class="mb-0">
                      {{item.description}}
                    </p>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row">
          <div v-for="(item, index) in careCardsInfo2" :key="index" class="col-12 col-sm-6 col-xl-3" style="padding-bottom:2rem">
            <b-card class="py-1">
              <div class="d-flex justify-content-center ">
                <div>
                  <div class="d-flex justify-content-center w-100">
                    <img :src="item.img" width="50px" />
                  </div>
                  <div class="text-center">
                    <h3 class="card-title my-1">{{ item.title }}</h3>
                    <p class="mb-0">
                      {{item.description}}
                    </p>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 sub-description">
      <div class="col-12 col-md-9 mb-2">
        <h3 class="text-black">How to use SlothMove Legal Care</h3>
        <p v-if="userPlan.plan > 0 " class="">
          To use SlothMove Legal simply send an email to hellow@slothmove.com and tell us about the most convenient time for a call back. We will help you get through to the right place
        </p>
        <p v-if="userPlan.plan < 1" class="">
          To use SlothMove Legal simply upgrade, let us know, and tell us about the most convenient time for a call back. We will help you get through to the right place
        </p>
        <div class="row mt-2">
          <div
            v-for="(item, index) in [
              '100% confidential',
              'Calls answered within 20 seconds',
              'Callback from a legal expert within 2 hours',
            ]"
            :key="index"
            class="col-12 mb-1 d-flex align-items-center"
          >
            <img :src="require('@/assets/images/icons/vignette.png')" alt="*" width="25px" class="ml-2" />
            <p class="mb-0 ml-1">{{item}}</p>
          </div>
        </div>
      </div>

      <div v-for="(area, index) in legalCareAreasDescription" :key="index" class="col-12 col-md-9 mb-2">
        <h3 class=" text-black">{{area.title}}</h3>
        <p class=""> {{area.description}} </p>
      </div>


      <!-- <div class="col-12 col-md-9 mb-2">
        <div
          class="d-flex align-items-center"
          v-b-toggle.collapse-1
          @click="toggleTerms = !toggleTerms"
        >
          <h3 class="font-weight-bolder mb-0">Key terms</h3>
          <i
            v-if="toggleTerms"
            class="ml-1 fas fa-angle-up animate__animated animate__rotateIn animate__faster"
            style="font-size: 20px"
          ></i>
          <i
            v-if="!toggleTerms"
            class="ml-1 fas fa-angle-down animate__animated animate__rotateIn animate__faster"
            style="font-size: 20px"
          ></i>
        </div>

        <b-collapse id="collapse-1" class="mt-2">
          <div v-for="item in [1, 2, 3, 4]" :key="item">
            <h5 class="font-weight-bolder mb-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua et falam si gore kslois. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            </h5>
          </div>
        </b-collapse>
      </div> -->

      <div class="col-12" v-if="userPlan.plan !== 0">
        <b-button
          v-on:click="submitLegalCare()"
          class="btn btn-primary"
        >
          <span
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
            v-if="loading"
          ></span>
          {{textButton}}
        </b-button>
      </div>

      <div class="col-12" v-if="userPlan.plan === 0">
        <button
          class="btn btn-primary"
          @click="toPricing()"
        >
          UPGRADE
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge, BCard, VBToggle, BCollapse } from "bootstrap-vue";
import { mapState } from 'vuex';
import axiosCustom from '@axios';
import Swal from 'sweetalert2';

const careCardsInfo1 = [
  {
    title: "Family",
    description: "Help with divorce, separation, children, finances, cohabitees wills, and powers of attorney",
    img: require("@/assets/images/legal-care/Family.png"),
  },
  {
    title: "Consumer",
    description: "Help with purchase and hiring of goods and services, credit, and holiday problems",
    img: require("@/assets/images/legal-care/Consumer.png"),
  },
  {
    title: "Motoring",
    description: "Help to contest fines, legal obligations, buying and selling cars, parking tickets and more",
    img: require("@/assets/images/legal-care/Motoring.png"),
  },
  {
    title: "Property",
    description: "Buying, selling and renting property, rights and obligations as a neighbour/tenant",
    img: require("@/assets/images/legal-care/Property.png"),
  },
];

const careCardsInfo2 = [
  {
    title: "Employment",
    description: "Your rights as an employee, disciplinary procedures, TUPE, redundancy etc...",
    img: require("@/assets/images/legal-care/Employment.png"),
  },
  {
    title: "Personal Injury",
    description: "Personal injuries sustained at work or in a traffic accident, in hospital treatment or victim",
    img: require("@/assets/images/legal-care/Personal Injury.png"),
  },
  {
    title: "Tax",
    description: "Help with inheritance tax, issues relating to self-employment, VAT and NI",
    img: require("@/assets/images/legal-care/Tax.png"),
  },
  {
    title: "Immigration",
    description: "Help with information and guidance (due to regulatory restrictions)",
    img: require("@/assets/images/legal-care/Immigration.png"),
  },
];

const legalCareAreasDescription= [
  {
    title: 'Family Law',
    description:'Support with family law includes co habitees, wills, power of attorney. The advice also extends to divorce, separation, and children. Family Law also covers advice on finance relating to those matters.'
  },
  {
    title: 'Consumer Law',
    description:'Consumer law covers everything around the purchasing or hiring of goods and services. It also contains elements around credit, and consumer law can extend to issues relating to holidays.'
  },
  {
    title: 'Motoring Law',
    description:'Advice on motoring law covers your contesting fines and penalties and your legal obligations as a vehicle owner. It also covers the law on buying and selling cars, parking tickets, speedind, other offences and penalties.'
  },
  {
    title: 'Immigration Law',
    description:'Immigration law refers to the national statutes, regulations, and legal precedents governing immigration into and deportation from a country. This includes information and guidance due to regulatory restrictions.'
  },
  {
    title: 'Property Law',
    description:'Property law is the area of law that governs the various forms of ownership in real property and personal property. Property refers to legally protected claims to resources, such as land and personal property, including intellectual property.'
  },
  {
    title: 'Employment Law',
    description:"UK employment laws are in place to make sure that both employers and employees are protected. Providing legislation on dismissal, holidays, pay, discrimination and more, these laws are in place to protects worker's rights while also safeguarding an employer's interests and keeping the relationship between the two fair."
  },
  {
    title: 'Personal Injury Law',
    description:'Personal injury law is based on the idea, that if someone does you a wrong, the wrong they have done should be put right. Personal injury claims aim to put the right wrong by making the party who caused the accident and your injury, pay you compensation for the harm they have caused you.'
  },
  {
    title: 'Tax information',
    description:'Advice on tax information covers inheritance tax and issues relating to self-employment. Under the UK tax umbrella, it also covers issues or queries pertaining to VAT and National Insurance.'
  }
]


export default {
  name: "LegalCare",
  directives: {
    "b-toggle": VBToggle,
  },
  components: {
    BBadge,
    BCard,
    BCollapse,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      premium: false,
      careCardsInfo1,
      careCardsInfo2,
      toggleTerms: false,
      legalCareAreasDescription,
      textButton: 'Submit',
      loading: false,
    };
  },
  created() {
    this.premium = this.userData?.moves[0].premium;
  },
  methods: {
    submitLegalCare(){
      this.loading = true
      this.textButton = 'Sending...'
      axiosCustom.post(`user/utils/requestLegalCare`, {
        email: this.userData.email
      })
      .then( () => {
        Swal.fire({
          html:
              `<div class="container-fluid pt-3">
              <h1 class="mb-2" style="text-align:center">Excellent, that's confirmed</h1>
              <h4 class="mb-2" style="text-align:left">A member from the Sloth Legal Care ream will be in touch within 24 hours</h4>
              <div class="row mt-3 mb-1 d-flex align-items-center justify-content-center">
                <div class="col-12 text-center">
                  <button class="btn btn-primary left-button " id="leftButton">
                    <p class="text-white font-weight-bolder mb-0" >
                      Ok
                    </p>
                  </button>
                </div>
              </div>
            </div>
            `,
          showClass: {
            popup: 'animate__animated animate__fadeInDown animate__faster'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp animate__faster'
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          showCancelButton: false,
          width: 530
        })
        .then(() => '')

        document.getElementById('leftButton').addEventListener('click', () => Swal.close())
      })
      .catch(() => {
        this.$toast.error("Error processing request", {
          style: {
            backgroundColor: '#ff5252',
            color: '#fff'
          }
        })
      })
      .finally(() => {
        this.loading = false
        this.textButton = 'Submit'
      })
    },
    toPricing(){
      this.$router.push('/pages/pricing');
    }
  },  
  computed:{
    ...mapState("app", ["userPlan","lastMove"]),
  }
};
</script>
<style lang="scss" scoped>
.title, .text-black {
  color: black;
}
.badge {
  padding: 5px 8px !important;
}

h5{
  color: #4e4e4e!important
}
.badge-primary {
  background-color: #1f476f !important;
  color: #cfe2e8 !important;
  box-shadow: 0px 0px 10px #1f476f;
}
.badge-success {
  background-color: #20476f !important;
  color: #f8edef !important;
  box-shadow: 0px 0px 10px #20476f;
  border-radius: 0.358rem;
}
.legal-care .card {
  margin-bottom: 0rem;
  height: 100%;
  border-radius: 15px;

  p{
    color: black!important
  }
}

.card .card-body {
  padding: 1.3rem !important;
}

.card-title {
  font-weight: 600;
  font-size: 25px;
  color: black;
}

i {
  color: #312e2e;
}

.sub-description {
  h3{
    font-weight: 600;
  }
}

/*
.sub-description p{
  color:black
}
*/

</style>
